import SingleTestimonial from "./SingleTestimonial";
import {Swiper, SwiperSlide} from "swiper/react";
import {A11y, Autoplay, Pagination} from "swiper/modules";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const Testamonial = () => {

    const testimonialContent = [
        {
            name: "Jane D",
            post: "E-commerce Business Owner",
            comment: " I've been using the online SEN Transport freight transport service for my e-commerce business for the past year, and I couldn't be happier. Their commitment to timely deliveries and secure handling of my goods has been a game-changer. It's allowed my business to thrive, and I highly recommend their services to fellow entrepreneurs."
        },
        {
            name: "Mark S",
            post: "Manufacturing Plant Manager",
            comment: "In the manufacturing industry, having a reliable freight transport partner is crucial. SEN Transport's online service has consistently met our high standards. Their attention to detail, accurate tracking, and on-time deliveries have helped us streamline our supply chain and reduce operational headaches."
        },
        {
            name: "Susan M.",
            post: "Small Business Owner",
            comment: "As a small business owner, I rely on efficient and cost-effective freight transport to get my products to customers. SEN Transport’s online service has been a real blessing. They offer competitive rates, and their communication is excellent. I've had zero issues with damaged goods or delays, and that peace of mind is priceless."
        },
        {
            name: "James R",
            post: "Logistics Coordinator",
            comment: "Coordinating complex logistics for a large company can be challenging, but the SEN Transport online freight transport service has made my job much easier. Their technology-driven approach provides real-time visibility into shipments, and their experienced team ensures that everything runs smoothly. It's a partnership I trust."
        },
        {
            name: "Emily L",
            post: "Online Retailer",
            comment: "Fast, reliable, and cost-effective – that's how I'd describe the SEN Transport’s online freight transport service I've been using. They've never let me down, even during peak seasons. Their user-friendly platform simplifies booking, and their customer support team is responsive and helpful. I can't imagine using anyone else."
        },
        {
            name: "David H",
            post: "Construction Project Manager",
            comment: "Construction projects depend on materials arriving on time, and the online freight transport service has been a vital part of our success. SEN Transport has consistently delivered building supplies promptly, allowing us to stay on schedule and meet our project deadlines. Their reliability has been a game-changer."
        },
    ];


    return (
        <div className=" w-full  justify-center items-center">
            <div className=" flex justify-center flex-col items-center my-6">
                <text className="font-ExpletusSans-BoldItalic text-xl text-lotus-red">
                    Testimonials
                </text>
                <text className="poppins-medium lg:text-4xl text-3xl text-center">
                    Voices of Satisfaction
                </text>
            </div>
            <div className="mx-10">
                <Swiper
                    modules={[Autoplay]}
                    autoplay={{
                        delay: 2000,
                        disableOnInteraction: false,
                    }}
                    loop={true}
                    spaceBetween={30}
                    breakpoints={{
                        480: {
                            slidesPerView: 1,
                            spaceBetween: 5,
                        },
                        768: {
                            slidesPerView: 2,
                            spaceBetween: 40,
                        },
                        1024: {
                            slidesPerView: 3,
                            spaceBetween: 30,
                        }}}
                className={'w-full'}>
                    {testimonialContent.map((testimonial, index) => (
                        <SwiperSlide key={index}>
                            <SingleTestimonial name={testimonial.name}
                                               post={testimonial.post}
                                               comment={testimonial.comment}/>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    );
}

export default Testamonial;
