import React, {useRef, useEffect} from "react";
import Footer from "../components/Footer/Footer";
import {Helmet} from "react-helmet-async";
import Hero from "../components/Hero";
import bgServicesImage from "../assets/services-hero.jpg";
import GetaQuote from "../components/GetaQuote";
import SenConnect from "../components/SenConnect";

function OurServives() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const ref = useRef(null);

    const handleClick = () => {
        ref.current?.scrollIntoView({behavior: "smooth"});
    };
    return (
        <>
            <Helmet>
                <title>Sen Courier</title>
                <meta
                    name="description"
                    content="Delivering urgent items on demand with zero hassle."
                />
                <link rel="canonical" href="/ourservices"/>
            </Helmet>
            <div className="w-full h-screen">
                <Hero type={"Services"} imgSrc={bgServicesImage}/>
            </div>
            <SenConnect/>
            <GetaQuote/>
            <Footer/>
        </>
    );
}

export default OurServives;
