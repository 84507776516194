import visionMisionImg from "../assets/vision-mission.jpg";
import React from "react";

const VisionMission = () => {
    return(
        <div className="flex flex-col">
            <div className="flex flex-row rounded-lg">
                <img alt="SEN Transport" src={visionMisionImg} width="100%" className="lg:px-24" />
            </div>
            <div className="flex flex-col lg:flex-row mt-8 lg:px-24">
                <div className="flex flex-col w-full lg:w-1/2 lg:border-r-2 px-5 lg:border-black lg:pr-5">
                    <text className="font-ExpletusSans-BoldItalic text-xl text-lotus-red">
                        Our Vision
                    </text>
                    <text className="text-5xl font-bold">We Deliver Quality</text>
                    <text className="mt-6 text-justify pb-5 font-bold">
                        To become the leading transport facilitator in the evolving landscape of technology-driven supply chain management, while simultaneously fostering job opportunities for job seekers and delivering reliable, sustainable, and profitable solutions to our valued shippers."</text>
                </div>
                <div className="flex flex-col w-full lg:w-1/2 lg:ml-5">
                    <text className="font-ExpletusSans-BoldItalic text-xl text-lotus-red">
                        Our Mission
                    </text>
                    <text className="text-5xl font-bold">We Deliver Satisfaction</text>
                    <text className="mt-6 text-justify pb-5 font-bold">
                        "To establish a platform that bridges shippers with carriers, fostering mutual benefits. Shippers can leverage our platform's auction system to secure the most advantageous deals and enjoy unparalleled transparency, while carriers have the opportunity to increase their earnings, free from third-party  interventions."</text>
                    <text className="mt-6 text-justify pb-5">
                        The client gains benefit from the auction for road transport services by receiving the best deal & complete transparency, whereas the carrier gets the opportunity to earn more since there is no involvement of a third party.</text>
                </div>
            </div>
        </div>
    );
}

export default VisionMission;