import React from "react";

const SingleTestimonial = (props) => {
    return (
        <div  className="sen-card bg-white border-gray-400 md:flex p-6 w-full" style={{marginRight:15, marginLeft:15}}>
            <div className="md:w-full">
                <ul className="flex justify-center lg:justify-start">
                    <li>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 96 960 960"
                            className="w-5 text-primary dark:text-primary-400">
                            <path
                                fill="gold"
                                d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                            />
                        </svg>
                    </li>
                    <li>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 96 960 960"
                            className="w-5 text-primary dark:text-primary-400">
                            <path
                                fill="gold"
                                d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                            />
                        </svg>
                    </li>
                    <li>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 96 960 960"
                            className="w-5 text-primary dark:text-primary-400">
                            <path
                                fill="gold"
                                d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                            />
                        </svg>
                    </li>
                    <li>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 96 960 960"
                            className="w-5 text-primary dark:text-primary-400">
                            <path
                                fill="gold"
                                d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                            />
                        </svg>
                    </li>
                    <li>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 96 960 960"
                            className="w-5 text-primary dark:text-primary-400">
                            <path
                                fill="gold"
                                d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                            />
                        </svg>
                    </li>
                </ul>
                <p className="poppins-medium text-lotus-red text-lg mt-3">{props.name}</p>
                <p className="text-gray-400 text-sm" style={{marginTop:-8}}>{props.post}</p>
                <p className="sen-red-text text-sm mt-4" style={{lineHeight:1.2}}>“<span className="sen-blue-text">{props.comment}</span>”</p>
            </div>
        </div>
    );
}

export default SingleTestimonial;