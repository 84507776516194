import React from "react";
import logo from "../../assets/logo.png";
import {
    faFacebookSquare,
    faInstagramSquare,
    faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import QuickLinks from "./QuickLinks";
import ContactInfo from "./ContactInfo";

function Footer() {
    const currentYear = new Date().getFullYear();

    return (
        <div className="flex flex-col items-center w-full bg-secondary-blue px-10 pt-10 lg:px-24 xl:pt-16 pb-8">
            <div className="w-full flex flex-col xl:flex-row xl:justify-between items-center xl:items-start">
                <div
                    className="w-full xl:w-1/4 flex flex-col justify-center items-center xl:items-center pb-10 xl:pb-0 mb-10 xl:mb-0 border-b xl:border-none border-gray-500">
                    <img src={logo} width="400"/>
                    <div className="mt-4 mb-2 text-center xl:text-center w-3/4 xl:w-full">
                        <text className="text-white text-2xl">
                            Get in touch with us
                        </text>
                    </div>
                    <div className="flex justify-center  items-center">
                        <a
                            href="https://www.facebook.com/profile.php?id=100092169171736&mibextid=9R9pXO"
                            target="_blank">
                            <FontAwesomeIcon
                                className="mr-6 cursor-pointer"
                                icon={faFacebookSquare}
                                size="2x"
                                color="#ffffff"
                            />
                        </a>
                        <a
                            href="https://instagram.com/sen.transport?igshid=NjIwNzIyMDk2Mg=="
                            target="_blank">
                            <FontAwesomeIcon
                                className="mr-6 cursor-pointer"
                                icon={faInstagramSquare}
                                size="2x"
                                color="#ffffff"
                            />
                        </a>
                        <a
                            href="https://www.linkedin.com/company/sentransport/"
                            target="_blank">
                            <FontAwesomeIcon
                                className="mr-6 cursor-pointer"
                                icon={faLinkedin}
                                size="2x"
                                color="#ffffff"
                            />
                        </a>
                        {/* <FontAwesomeIcon
              className="mr-6 cursor-pointer"
              icon={faYoutubeSquare}
              size="2x"
              color="#ffffff"
            /> */}
                    </div>
                </div>
                <div className="w-full xl:w-1/2 flex flex-col xl:flex-row justify-center xl:justify-between items-center xl:items-start">
                    <div className="w-full flex flex-col items-center mb-10 mt-4 xl:mb-0 xl:w-1/2 xl:border-r-2 border-gray-500">
                        <text className="text-2xl text-white font-bold">Quick Links</text>
                        <div className="w-full mt-6 flex flex-col xl:flex-row xl:items-start items-center">
                            <div className="w-full xl:w-1/2 flex flex-col xl:items-start items-center">
                                <QuickLinks title={"Home"} link={"/home"}/>
                                <QuickLinks title={"Services"} link={"/ourservices"}/>
                                <QuickLinks title={"About Us"} link={"/about"}/>
                            </div>
                            <div className="w-full xl:w-1/2 flex flex-col xl:items-start items-center">
                                <QuickLinks title={"Contact Us"} link={"/contact"}/>
                                <QuickLinks title={"Careers"} link={"/team"}/>
                            </div>
                        </div>
                    </div>
                    <div className="w-full xl:w-1/2 mt-4 xl:ml-10 flex flex-col items-center xl:items-start mb-6 xl:mb-0">
                        <text className="text-2xl text-white font-bold">Contact Info</text>
                        <div className="mt-4 flex flex-col items-center xl:items-stretch">
                            <div className="">
                                <ContactInfo icon="phone" title="0420 846 346"/>
                            </div>
                            <div className="">
                                <ContactInfo
                                    icon="map"
                                    title="538 Woodville Rd, Guildford NSW 2161"
                                />
                            </div>
                            <ContactInfo icon="mail" title="Zen@sentransport.com.au"/>
                        </div>
                    </div>
                </div>

            </div>
            <div className="mt-16 border-t pt-6 border-gray-500 w-full flex justify-center">
                <text className="text-white text-md text-center py-15">
                    © {currentYear} Sen Transport and Logistics PTY Ltd.<br/>
                    All Rights Reserved.
                </text>
            </div>
        </div>
    );
}

export default Footer;
