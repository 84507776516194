import React, {useRef, useEffect} from "react";
import Footer from "../components/Footer/Footer";
import {Helmet} from "react-helmet-async";
import Hero from "../components/Hero";
import bgAboutUsImage from "../assets/bg-about-us.jpg";
import MeetOurCrew from "../assets/meet-our-crew.jpg";
import theStoryImg from "../assets/the-story.jpg";
import visionMisionImg from "../assets/vision-mission.jpg";
import AchivementAboutUs from "../components/AchivementAboutUs";
import VisionMission from "../components/Vision&Mission";
import TeamStory from "../components/Team&Story";
import HistoryAboutUs from "../components/HistoryAboutUs";

function AboutUs() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const ref = useRef(null);

    const handleClick = () => {
        ref.current?.scrollIntoView({behavior: "smooth"});
    };

    return (
        <>
            <Helmet>
                <title>About Us - Sen Transport</title>
                <meta
                    name="description"
                    content="Launched in 2017 , Sen Transport has come a long way so far eliminating inefficiencies, embracing success. The foundation for the company was laid upon an idea Neranjan Senanayake came up with, in order to fulfil the gaps that were identified in the transportation industry."
                />
                <link rel="canonical" href="/about"/>
            </Helmet>
            <div className="w-full h-screen">
                <Hero type={"AboutUs"} imgSrc={bgAboutUsImage}/>
            </div>

            <TeamStory/>
            <VisionMission/>
            <AchivementAboutUs/>
            <HistoryAboutUs />
            <Footer/>
        </>
    );
}

export default AboutUs;
