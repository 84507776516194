import React, {useRef} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHandsHoldingCircle, faHeadset, faMapLocationDot} from "@fortawesome/free-solid-svg-icons";
import emailjs from "@emailjs/browser";

const GetaQuote = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm(
                "service_sen_web",
                "get_a_quote",
                form.current,
                "spUwMSRLKfQt2J351"
            )
            .then(
                (result) => {
                    console.log(result.text);
                    console.log("messge sent");
                    alert("Your message is sent!");
                },
                (error) => {
                    console.log(error.text);
                }
            );
    };


return (
        <>
            <div className="relative">
                <div
                    className="absolute inset-0 bg-getaquote-bgImage bg-no-repeat bg-cover"
                    style={{ zIndex: -1 }}
                ></div>
                <div
                    className="absolute inset-0 bg-gradient-to-l from-black to-transparent"
                    style={{ mixBlendMode: 'multiply' }}
                ></div>
                <div
                    className="relative z-10 w-full px-10 xl:px-16 lg:px-16 py-16 flex lg:flex-row flex-col justify-between items-center">
                    <form
                        className="lg:inline w-full lg:w-6/12"
                        ref={form}
                        onSubmit={sendEmail}
                    >
                        <div className="bg-white p-8 flex flex-col justify-center rounded-lg items-center">
                            <div className="w-full">
                                <text className="font-semibold">Personal Info</text>
                                <div className="flex flex-row w-full">
                                    <div className="flex flex-col mt-4 w-1/2 xs:w-full mr-2">
                                        <label className="block mb-1 text-xs font-medium text-gray-900">Customer
                                            name</label>
                                        <input
                                            placeholder="Customer name"
                                            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-full sen-input  block w-full p-2.5"
                                            type="text"
                                            name="customer_name"></input>
                                    </div>
                                    <div className="flex flex-col mt-4 w-1/2 xs:w-full ml-2">
                                        <label className="block mb-1 text-xs font-medium text-gray-900 ">Company
                                            Name</label>
                                        <input
                                            placeholder="Company Name"
                                            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-full sen-input  block w-full p-2.5"
                                            type="text"
                                            name="company_name"></input>
                                    </div>
                                </div>
                                <div className="flex flex-row w-full">
                                    <div className="flex flex-col mt-4 w-1/2 xs:w-full mr-2">
                                        <label className="block mb-1 text-xs font-medium text-gray-900 ">Email
                                            Address</label>
                                        <input
                                            placeholder="Email Address"
                                            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-full sen-input  block w-full p-2.5"
                                            type="text"
                                            name="email"></input>
                                    </div>
                                    <div className="flex flex-col mt-4 w-1/2 ml-2">
                                        <label className="block mb-1 text-xs font-medium text-gray-900 ">Phone
                                            Number</label>
                                        <input
                                            placeholder="Phone Number"
                                            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-full sen-input  block w-full p-2.5"
                                            type="text"
                                            name="phone_no"></input>
                                    </div>
                                </div>

                                <div className="w-full mt-8">
                                    <text className="font-semibold">Shipment Data</text>
                                    <div className="flex flex-row w-full">
                                        <div className="flex flex-col mt-4 w-1/2 mr-2">
                                            <label className="block mb-1 text-xs font-medium text-gray-900">Sender
                                                Address</label>
                                            <input
                                                placeholder="Sender Address"
                                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-full sen-input  block w-full p-2.5"
                                                type="text"
                                                name="sender_addr"></input>
                                        </div>
                                        <div className="flex flex-col mt-4 w-1/2 ml-2">
                                            <label className="block mb-1 text-xs font-medium text-gray-900 ">Receiver
                                                Address</label>
                                            <input
                                                placeholder="Receiver Address"
                                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-full sen-input  block w-full p-2.5"
                                                type="text"
                                                name="recivr_addr"></input>
                                        </div>
                                    </div>
                                    <div className="flex flex-row w-full">
                                        <div className="flex flex-col mt-4 w-1/2 mr-2">
                                            <label className="block mb-1 text-xs font-medium text-gray-900 ">Pickup
                                                Time</label>
                                            <input
                                                placeholder=" "
                                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-full sen-input  block w-full p-2.5"
                                                type="datetime-local"
                                                name="pickup"></input>
                                        </div>
                                        <div className="flex flex-col mt-4 w-1/2 ml-2">
                                            <label className="block mb-1 text-xs font-medium text-gray-900 ">Drop
                                                Time</label>
                                            <input
                                                placeholder=" "
                                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-full sen-input  block w-full p-2.5"
                                                type="datetime-local"
                                                name="dropoff"></input>
                                        </div>
                                    </div>
                                    <div className="flex flex-row w-full">
                                        <div className="flex flex-col mt-4 w-1/2 mr-2">
                                            <label className="block mb-1 text-xs font-medium text-gray-900 ">Weight
                                                (Kg)</label>
                                            <input
                                                placeholder="Weight (Kg)"
                                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-full sen-input  block w-full p-2.5"
                                                type="number"
                                                name="weight"></input>
                                        </div>
                                        <div className="flex flex-col mt-4 w-1/2 ml-2">
                                            <label className="block mb-1 text-xs font-medium text-gray-900 ">No. of
                                                pallets</label>
                                            <input
                                                placeholder="No. of pallets"
                                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-full sen-input  block w-full p-2.5"
                                                type="number"
                                                name="pallets"></input>
                                        </div>
                                    </div>
                                    <div className="flex flex-col w-full mt-4">
                                        <label className="block mb-1 text-xs font-medium text-gray-900 ">Dimensions
                                            (cm)</label>
                                        <div className="flex flex-row w-full">
                                            <div className="flex flex-col w-1/3 mr-2">
                                                <input
                                                    placeholder="Length"
                                                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-full sen-input  block w-full p-2.5"
                                                    type="number"
                                                    name="length"></input>
                                            </div>
                                            <div className="flex flex-col w-1/3 mx-2">
                                                <input
                                                    placeholder="Width"
                                                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-full sen-input  block w-full p-2.5"
                                                    type="number"
                                                    name="width"></input>
                                            </div>
                                            <div className="flex flex-col w-1/3 ml-2">
                                                <input
                                                    placeholder="Height"
                                                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-full sen-input  block w-full p-2.5"
                                                    type="number"
                                                    name="height"></input>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <input
                                className="border-2 flex justify-center items-center text-center py-4 rounded-full mt-10 border-lotus-red hover:cursor-pointer w-full bg-red-600 text-white hover:border-gray-300"
                                value="REQUEST A QUOTE"
                                type="submit"></input>
                        </div>
                    </form>
                    <div className="w-full lg:w-5/12">
                        <text className="font-ExpletusSans-BoldItalic text-xl text-white">
                            Calculator
                        </text>
                        <div className="flex justify-center flex-col mb-6">
                            <text className="poppins-medium lg:text-5xl text-3xl text-white">
                                Get a Quote
                            </text>
                        </div>
                        <text className="text-white lg:text-base text-sm">
                            Our dedicated team is here to help you every step of the way. Just
                            provide us with the necessary details, and by submitting your
                            request, discover the potential savings waiting for you on your
                            delivery expenses.
                        </text>
                        <div className="flex flex-row mt-10 border-4 rounded-xl p-4 border-collapse">
                            <div className="flex w-1/3 flex-col justify-center items-center border-r-4">
                                <FontAwesomeIcon icon={faHeadset} size="3x" color="#ffffff"/>
                                <text className="text-white font-bold mt-2">Support 24/7</text>
                            </div>
                            <div className="flex w-1/3 flex-col justify-center items-center border-r-4">
                                <FontAwesomeIcon
                                    icon={faMapLocationDot}
                                    size="3x"
                                    color="#ffffff"
                                />
                                <text className="text-white font-bold mt-2">Online Tracking</text>
                            </div>
                            <div className="flex w-1/3 flex-col justify-center items-center">
                                <FontAwesomeIcon
                                    icon={faHandsHoldingCircle}
                                    size="3x"
                                    color="#ffffff"
                                />
                                <text className="text-white font-bold mt-2">Insurance</text>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default GetaQuote;