import React, { useEffect} from "react";
import Footer from "../components/Footer/Footer";
import bgHomeImage from "../assets/Website Hero Image.jpg";
import emailjs from "@emailjs/browser";
import {Helmet} from "react-helmet-async";
import Hero from "../components/Hero";
import Testamonial from "../components/Testamonial";
import WhatWeOffer from "../components/WhatWeOffer";
import CountingNumbersContainer from "../components/CountingNumbersContainer";
import GetaQuote from "../components/GetaQuote";

function HomePage() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    function sendEmail() {
        const templateParams = {
            name: "James",
            notes: "Check this out!",
        };
        emailjs
            .send(
                "service_al4c9la",
                "template_7l5lgfo",
                templateParams,
                "pqP_Kk13ylAANuEA6"
            )
            .then(
                (response) => {
                    console.log("SUCCESS!", response.status, response.text);
                },
                (err) => {
                    console.log("FAILED...", err);
                }
            );
    }

    return (
        <>
            <Helmet>
                <title>Home - Sen Transport</title>
                <meta
                    name="description"
                    content="We, Sen Transport is the third party that links both ends of pick up & delivery.
          Sen is equipped with sophisticated features, providing you efficient & innovative delivery solutions. Since your choice is our priority, Sen operations are simplified for easy access. We provide safe & efficient deliveries leaving nothing behind for you to worry about."
                />
                <link rel="canonical" href="/home"/>
            </Helmet>
            <div className="w-full h-screen">
                <Hero type={"Home"} imgSrc={bgHomeImage}/>
            </div>
            <WhatWeOffer/>
            <CountingNumbersContainer/>
            <Testamonial/>
            <GetaQuote/>
            <Footer/>
        </>
    );
}

export default HomePage;
