import Typewriter from "typewriter-effect";
import NavBar from "./NavBar/NavBar";
import React from "react";

const Hero = (props) => {
    return (<div className="relative w-full h-screen ">
            <img
                alt="SenTransport"
                src={props.imgSrc}
                className="absolute w-full h-full object-cover"
            />
            <div className="absolute h-full w-full bg-gradient-to-r  from-black"/>
            <div className="absolute w-full h-full">
                {props.type === "Home" && (
                    <div className="flex items-start flex-col mt-40 pt-36 sm:mt-96 ml-10 sm:ml-36">

                        <div className="text-6xl poppins-medium sm:text-6xl">
                            <text className=" text-white my-1">Superior Transport Service</text>
                            <div className="flex flex-row">
                                <text className=" text-white my-1">at your fingertips! &nbsp;</text>
                                <div className="text-lotus-red font-bold my-1 poppins-medium hidden sm:block">
                                    <Typewriter
                                        options={{
                                            strings: [" Fast", " Safe", " Secure",], autoStart: true, loop: true,
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>)}
                {props.type === "Services" && (
                    <div className="flex items-start flex-col mt-56 sm:mt-56 ml-10 sm:ml-36">
                        <div className="text-6xl poppins-medium sm:text-6xl">
                            <text className=" text-lotus-red my-1">Innovative</text>
                            <div className="flex flex-col">
                                <text className=" text-white my-1 text-4xl">Transport Solutions</text>
                                <text className=" text-white my-1 text-xl">Tech-Powered, Versatile & Reliable</text>
                                <text className=" text-white my-1 text-xl">Nationwide Delivery Services</text>
                            </div>
                        </div>
                    </div>)}
                {props.type === "ContactUs" && (
                    <div className="flex items-start flex-col mt-80 sm:mt-80 ml-10 sm:ml-36">
                        <div className="text-6xl poppins-medium sm:text-6xl">
                            <div className="flex flex-col">
                                <text className=" text-white my-1 text-4xl">Contact Sen Transport</text>
                                <text className=" text-lotus-red my-1">Your Inquiries</text>
                                <text className=" text-white my-1 text-4xl">Our Priority in Delivering Excellence</text>
                            </div>
                        </div>
                    </div>)}
                {props.type === "AboutUs" && (
                    <div className="flex items-start flex-col mt-56 sm:mt-56 ml-10 sm:ml-36">
                        <div className="text-6xl poppins-medium sm:text-6xl">
                            <text className=" text-lotus-red my-1">Sen Transport</text>
                            <div className="flex flex-col">
                                <text className=" text-white my-1 text-4xl">Your Partner for</text>
                                <text className=" text-white my-1 text-4xl">Reliable and Diverse Deliveries</text>
                            </div>
                        </div>
                    </div>)}
                {props.type === "Careers" && (
                    <div className="flex items-start flex-col mt-80 sm:mt-80 ml-10 sm:ml-36">
                        <div className="text-6xl poppins-medium sm:text-6xl">
                            <div className="flex flex-col">
                                <text className=" text-white my-1 text-4xl">Sen Transport Offers</text>
                                <text className=" text-lotus-red my-1">Diverse & Rewarding</text>
                                <text className=" text-white my-1 text-4xl">Career Paths</text>
                            </div>
                        </div>
                    </div>)}
            </div>
            <div className="abolute">
                <NavBar/>
            </div>
        </div>);
}

export default Hero;