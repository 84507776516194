import MeetOurCrew from "../assets/meet-our-crew.jpg";
import theStoryImg from "../assets/the-story.jpg";
import React from "react";

const TeamStory = () => {
    return(
        <>
        <div className="w-full flex lg:flex-row flex-col items-center lg:justify-between lg:py-10 lg:px-24 ">
            <div className="hidden lg:inline w-full lg:w-5/12 my-20">
                <img alt="SEN Transport" src={MeetOurCrew} width="600" height="100%" className="rounded-lg" />
            </div>
            <div className="lg:hidden w-full ">
                <img alt="SEN Transport" src={MeetOurCrew} width="600" height="100%" className="" />
            </div>
            <div className="flex flex-col w-full lg:w-6/12 my-20 px-6">
                <text className="font-ExpletusSans-BoldItalic text-lg text-lotus-red">
                    Our Team
                </text>
                <text className="text-5xl font-bold">Meet Our Crew</text>
                <text className="mt-6 text-justify pb-5">
                    Sen serves as your primary connection, offering an exclusive opportunity to streamline your supply chain. We expertly organize and oversee all your deliveries, consolidating your packages securely at our Sydney warehouse before dispatch. With our extensive and adaptable fleet of vehicles, we're equipped to meet your diverse needs, delivering a flexible and cost-effective service. Every delivery is meticulously pre-planned, ensuring a hassle-free experience.                    </text>
            </div>
        </div>

    <div className="w-full flex lg:flex-row flex-col items-center lg:justify-between lg:px-24 ">
        <div className="flex flex-col w-full lg:w-6/12 my-20 px-6">
            <text className="font-ExpletusSans-BoldItalic text-lg text-lotus-red">
                How We Evolved
            </text>
            <text className="text-5xl font-bold">The Story</text>
            <text className="mt-6 text-justify font-bold pb-5">
                Launched in 2017 , Sen Transport has come a long way so far eliminating inefficiencies embracing success.</text>
            <text className="mt-6 text-justify pb-5">
                This venture was conceived from a visionary perspective, recognizing the voids within the transportation industry and the opportunity to fill them. The keen insight that an effective delivery service in the bustling metropolis of Melbourne presented an ideal business prospect was key to its inception. Backed by a wealth of knowledge and experience, self-confidence and a deep understanding of the intricacies of the transportation sector were pivotal in the birth of Sen Transport.
            </text>
        </div>
        <div className="hidden lg:inline w-full lg:w-5/12 my-20">
            <img alt="SEN Transport" src={theStoryImg} width="600" height="100%" className="rounded-lg" />
        </div>
        <div className="lg:hidden w-full ">
            <img alt="SEN Transport" src={theStoryImg} width="600" height="100%" className="" />
        </div>
    </div>
        </>
    );

}

export default TeamStory;