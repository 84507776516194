import React, {useEffect, useState} from "react";
import logo from "../../assets/logo.png";
import Hamburger from "hamburger-react";
import {NavLink} from "react-router-dom";
import classes from "./NavBarButton.module.css";
import {useLocation} from "react-router-dom/cjs/react-router-dom";

const NavBar = () => {
    const [isOpen, setOpen] = useState(false);
    const location = useLocation();
    const activeState = location.pathname;

    useEffect(() => {
        console.log("Active State: ", activeState);
    }, []);

    return (
        <div className="flex flex-row fixed font-Montserrat z-20">

            <div
                className={`flex flex-row xl:flex-row justify-between items-center lg:py-0 pt-3 pr-8 lg:pl-24 pl-10 w-auto lg:absolute xl:static left-0 transition-all duration-500 ease-in-out ${
                    isOpen ? "top-16 md:top-24 lg:top-24 py-6 bg-white" : "top-[-490px]"
                }`}
                style={{backgroundColor: '#2B2E4A'}}>
                <img
                    src={logo}
                    className="lg:w-1/4 w-1/3 lg:inline hidden"
                    alt="logo"
                />
                <img src={logo} className="lg:w-1/5 w-1/4 lg:hidden" alt="logo"/>
                <div
                    onClick={() => setOpen(!isOpen)}
                    className="text-3xl right-16 top-8 cursor-pointer xl:hidden">
                    <div className="lg:hidden">
                        <Hamburger
                            toggled={isOpen}
                            toggle={setOpen}
                            color="white"
                            size="25"
                        />
                    </div>
                </div>
                <div
                    className={`xl:flex xl:flex-row flex-col justify-end items-center absolute xl:static left-0 w-full transition-all duration-300 ease-in-out ${
                        isOpen
                            ? "top-16 md:top-24 lg:top-24 py-6 bg-secondary-blue-dark" +
                            ""
                            : "top-[-490px]"
                    }`}>
                    <div>
                        {isOpen ? (
                            <div className="text-black flex flex-col w-full">
                                <header
                                    className={`${classes.header} ${(activeState === '/home' || activeState === '/') ? 'bg-red-500' : ''}`}>
                                    <div className="flex justify-center items-center text-center">
                                        <NavLink
                                            activeClassName="text-red"
                                            to="/home"
                                            className="xl:p-2 xl:m-4 my-3 text-secondary-blue font-semibold bold hover:text-lotus-red 2xl:text-lg text-lg">
                                            Home
                                        </NavLink>
                                    </div>
                                </header>
                                <header
                                    className={`${classes.header} ${activeState === '/ourservices' ? 'bg-red-500' : ''}`}>
                                    <div className="flex justify-center items-center text-center">
                                        <NavLink
                                            activeClassName={classes.active}
                                            to="/ourservices"
                                            className="xl:p-2 xl:m-4 my-3 text-secondary-blue font-semibold bold hover:text-lotus-red 2xl:text-lg text-lg">
                                            Services
                                        </NavLink>
                                    </div>
                                </header>
                                <header
                                    className={`${classes.header} ${activeState === '/about' ? 'bg-red-500' : ''}`}>
                                    <div className="flex justify-center items-center text-center">
                                        <NavLink
                                            activeClassName={classes.active}
                                            to="/about"
                                            className="xl:p-2 xl:m-4 my-3 text-secondary-blue font-semibold bold hover:text-lotus-red 2xl:text-lg text-lg">
                                            About Us
                                        </NavLink>
                                    </div>
                                </header>
                                <header
                                    className={`${classes.header} ${activeState === '/contact' ? 'bg-red-500' : ''}`}>
                                    <div className="flex justify-center items-center text-center">
                                        <NavLink
                                            activeClassName={classes.active}
                                            to="/contact"
                                            className="xl:p-2 xl:m-4 my-3 text-secondary-blue font-semibold bold hover:text-lotus-red 2xl:text-lg text-lg">
                                            Contact Us
                                        </NavLink>
                                    </div>
                                </header>
                                <header
                                    className={`${classes.header} ${activeState === '/careers' ? 'bg-red-500' : ''}`}>
                                    <div className="flex justify-center items-center text-center">
                                        <NavLink
                                            activeClassName={classes.active}
                                            to="/careers"
                                            className="xl:p-2 xl:m-4 my-3 text-secondary-blue font-semibold bold hover:text-lotus-red 2xl:text-lg text-lg">
                                            Careers
                                        </NavLink>
                                    </div>
                                </header>
                            </div>
                        ) : (
                            <div className="lg:inline hidden">
                                <div className="flex flex-row justify-center items-center">
                                    <header
                                        className={`${classes.header} hover:border-b-8 border-lotus-red ${activeState === '/home' ? 'bg-red-500 border-b-8 border-lotus-red' : ''}`}>
                                        <div className="flex justify-center items-center text-center">
                                            <NavLink
                                                activeClassName={classes.active}
                                                to="/home"
                                                className="xl:p-2 xl:m-4 m-10 text-secondary-blue font-semibold bold hover:text-lotus-red 2xl:text-lg text-lg">
                                                Home
                                            </NavLink>
                                        </div>
                                    </header>
                                    <header
                                        className={`${classes.header} hover:border-b-8 border-lotus-red ${activeState === '/ourservices' ? 'bg-red-500 border-b-8 border-lotus-red' : ''}`}>
                                        <div className="flex justify-center items-center text-center">
                                            <NavLink
                                                activeClassName={classes.active}
                                                to="/ourservices"
                                                className="xl:p-2 xl:m-4 m-10 text-secondary-blue font-semibold bold hover:text-lotus-red 2xl:text-lg text-lg">
                                                Services
                                            </NavLink>
                                        </div>
                                    </header>
                                    <header
                                        className={`${classes.header} hover:border-b-8 border-lotus-red ${activeState === '/about' ? 'bg-red-500 border-b-8 border-lotus-red' : ''}`}>
                                        <div className="flex justify-center items-center text-center">
                                            <NavLink
                                                activeClassName={classes.active}
                                                to="/about"
                                                className="xl:p-2 xl:m-4 m-10 text-secondary-blue font-semibold bold hover:text-lotus-red 2xl:text-lg text-lg">
                                                About Us
                                            </NavLink>
                                        </div>
                                    </header>
                                    <header
                                        className={`${classes.header} hover:border-b-8 border-lotus-red ${activeState === '/contact' ? 'bg-red-500 border-b-8 border-lotus-red' : ''}`}>
                                        <div className="flex justify-center items-center text-center">
                                            <NavLink
                                                activeClassName={classes.active}
                                                to="/contact"
                                                className="xl:p-2 xl:m-4 m-10 text-secondary-blue font-semibold bold hover:text-lotus-red 2xl:text-lg text-lg">
                                                Contact Us
                                            </NavLink>
                                        </div>
                                    </header>
                                    <header
                                        className={`${classes.header} hover:border-b-8 border-lotus-red ${activeState === '/careers' ? 'bg-red-500 border-b-8 border-lotus-red' : ''}`}>
                                        <div className="flex justify-center items-center text-center">
                                            <NavLink
                                                activeClassName={classes.active}
                                                to="/careers"
                                                className="xl:p-2 xl:m-4 m-10 text-secondary-blue font-semibold bold hover:text-lotus-red 2xl:text-lg text-lg">
                                                Careers
                                            </NavLink>
                                        </div>
                                    </header>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
export default NavBar;
