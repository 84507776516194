import React from "react";
import timeLineImg from "../assets/time-line.png";
import TimeLineCard from "./TimeLineCard";
import {faCircleCheck} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function HistoryAboutUs() {
  const timeLine = [
    {
      year: "2017",
      title: "Initiated Sen Transport",
      desc: "Sen Transport originated in Melbourne, initially as a dedicated courier service specializing in medical supplies transportation."
    },
    {
      year: "2019",
      title: "Expansion",
      desc: "Sen Transport experienced growth by incorporating 12 Taut liner operations into their services and successfully launched the innovative Freight Consolidation Hub."
    },
    {
      year: "2020",
      title: "Sen in Sydney",
      desc: "Sen Transport extended its operations to Sydney in response to the increasing demand for transportation services."
    },
    {
      year: "2022",
      title: "Line-haul operations",
      desc: "Sen Transport implemented inter-state shuttle services to enhance their capabilities for scheduled freight deliveries."
    },
    {
      year: "2024",
      title: "Sen Connect Launched",
      desc: "Sen Transport implemented inter-state shuttle services to enhance their capabilities for scheduled freight deliveries."
    },
  ]

  return (
    <div className="lg:px-24 lg:py-10 px-10 py-20">
      <div className="mb-20">
        <text className="font-ExpletusSans-BoldItalic text-lg text-lotus-red">
          History
        </text>
        <div className="lg:text-5xl text-3xl font-bold">
          <text>Milestones</text>
        </div>
        <div className="mt-2 flex flex-col">
          <text className="text-justify font-bold">
            Here's a quick insight on how we have evolved through the years in
            the business.
          </text>
          <text className="text-justify mt-4">
            This venture was conceived from the visionary mind of Neranjan Senanayake, who recognized the voids within the transportation industry and saw the opportunity to fill them. His keen insight led him to believe that an effective delivery service in the bustling metropolis of Melbourne presented an ideal business prospect. Backed by a wealth of knowledge and experience, Neranjan's self-confidence and deep understanding of the intricacies of the transportation sector were pivotal in the birth of Sen Transport.
          </text>
        </div>
      </div>

      {/*Mobile view*/}
      <div className="flex flex-col lg:hidden mt-4">
        {timeLine.map((timeCard) => (
            <div className="mt-6">
              <div>
                <text className="text-lotus-red text-5xl font-semibold ">{timeCard.year}</text>
              </div>
              <div className="p-6 w-56 flex flex-col m-6 shadow-lg">
                <text className="text-sm font-bold">{timeCard.title}</text>
                <text className="text-xs mt-4">
                  {timeCard.desc}
                </text>
              </div>
            </div>
        ))}
      </div>

      {/*desktop view*/}
      <div className="relative mt-8 hidden lg:inline">
        <div className="lg:flex flex-row justify-center mt-8">
          {timeLine.map((timeCard) => (
              <div className="flex flex-col w-3/12">
                <div className="relative flex items-center justify-center mb-4">
                  <FontAwesomeIcon className="absolute text-lotus-red text-4xl bg-white" icon={faCircleCheck} />
                  <div className="flex-grow h-0.5 bg-red-600"></div>
                </div>
                <div className="mx-5">
                <TimeLineCard year={timeCard.year} title={timeCard.title} desc={timeCard.desc} />
                </div>
              </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default HistoryAboutUs;
