import senLocal from "../assets/sen-local-dist.jpg"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowUpRightFromSquare, faCircleCheck} from "@fortawesome/free-solid-svg-icons";
import React from "react";

const ServiceCard = (props) => {
    return (
      <div className="service-card rounded-lg">
          <div className="flex flex-col items-center">
              <div className="m-9 overflow-hidden rounded-lg">
                  <img alt="senConsolidated" src={props.image} style={{maxHeight: '50%', width: 'auto'}}/>
              </div>
              <div className="flex flex-col w-full px-10">
                  <text className="font-ExpletusSans-BoldItalic text-xl text-lotus-red">
                      Our Services
                  </text>
                  <text className="poppins-medium lg:text-4xl text-3xl">
                      {props.title}
                  </text>
                  <div className="text-lg text-black poppins-regular mb-4">
                      You're just a few steps away from enjoying
                  </div>
                  <div className="w-full flex flex-col xl:items-start items-start mb-4 ml-4">
                      {props.content.map(step => (
                          <text className="font-bold"><FontAwesomeIcon icon={faCircleCheck} className="text-lotus-red mr-2"/>{step}</text>
                      ))}
                  </div>
                  {/*<a href="">*/}
                      <div className="flex justify-center items-center bg-lotus-red rounded py-4 mb-9 w-full">
                        <span className="flex items-center">
                        <text className="text-white poppins-medium mr-2">Coming Soon</text>
                    </span>
                      </div>
                  {/*</a>*/}
              </div>

          </div>

      </div>
    );
}

export default ServiceCard;