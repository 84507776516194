import React, { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLocationDot, faPhone} from "@fortawesome/free-solid-svg-icons";
import emailjs from "@emailjs/browser";

function ContactForm() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_sen_web",
        "contact_us",
        form.current,
        "spUwMSRLKfQt2J351"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("messge sent");
          alert("Your message is sent!");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <div className="w-full flex lg:flex-row flex-col lg:justify-between py-28 lg:px-24 px-10">
      <div className="flex flex-col w-full lg:w-5/12">
        <text className="font-ExpletusSans-BoldItalic text-lg text-lotus-red">
          Contact Us
        </text>
        <text className="text-5xl font-bold">Contact Info</text>
        <div className="flex flex-row mt-4 items-center">
          <div className="flex flex-col w-1/6">
            <FontAwesomeIcon icon={faPhone} className="text-lotus-red text-2xl"/>
          </div>
          <div className="flex flex-col w-3/4">
            <text className="text-sm poppins-large">Phone Number</text>
            <text className="text-xl poppins-regular">0420 846 346</text>
          </div>
        </div>
        <div className="flex flex-row mt-4 items-center">
          <div className="flex flex-col w-1/6">
            <FontAwesomeIcon icon={faLocationDot} className="text-lotus-red text-2xl"/>
          </div>
          <div className="flex flex-col w-3/4">
            <text className="text-sm poppins-large">Location</text>
            <text className="text-xl poppins-regular">538 Woodville Rd,</text>
            <text className="text-xl poppins-regular">Guildford NSW 2161</text>
          </div>
        </div>
        <div className="flex flex-row mt-4 items-center">
          <div className="flex flex-col w-1/6">
            <FontAwesomeIcon icon={faEnvelope} className="text-lotus-red text-2xl"/>
          </div>
          <div className="flex flex-col w-3/4">
            <text className="text-sm poppins-large">Email Address</text>
            <text className="text-xl poppins-regular">Zen@sentransport.com.au</text>
          </div>
        </div>
      </div>
      <form
          id="contact_us_form"
        className="w-full lg:w-5/12  border p-8 rounded-xl bg-secondary-blue flex flex-col justify-between lg:mt-0 mt-24"
        ref={form}
        onSubmit={sendEmail}>
        <div className="xl:inline hidden">
          <div className="flex flex-row justify-between my-6">
            <div className="flex flex-col ">
              <text className="text-sm text-white">First Name</text>
              <input className="rounded" type="text" name="first_name"></input>
            </div>
            <div className="flex flex-col ">
              <text className="text-sm text-white">Last Name</text>
              <input className="rounded" type="text" name="last_name"></input>
            </div>
          </div>
          <div className="flex flex-row justify-between mb-6">
            <div className="flex flex-col ">
              <text className="text-sm text-white">Email</text>
              <input className="rounded" type="email" name="email"></input>
            </div>
            <div className="flex flex-col ">
              <text className="text-sm text-white">Phone Number</text>
              <input className="rounded" type="text" name="phone"></input>
            </div>
          </div>
          <div className="flex flex-col mb-6">
            <text className="text-sm text-white">Message</text>
            <textarea
              className="border-b rounded h-10"
              name="message"
              rows="5"
            form="contact_us_form"></textarea>
          </div>
        </div>
        <div className="hidden lg:inline xl:hidden">
          <div className="flex flex-col">
            <text className="text-sm text-white">First Name</text>
            <input
              className="rounded"
              type="text"
              name="first_name"></input>
          </div>
          <div className="flex flex-col mt-3">
            <text className="text-sm text-white">Last Name</text>
            <input className="rounded" type="text" name="last_name"></input>
          </div>
          <div className="flex flex-col mt-3">
            <text className="text-sm text-white">Email</text>
            <input className="rounded" type="email" name="email"></input>
          </div>
          <div className="flex flex-col mt-3">
            <text className="text-sm text-white">Phone Number</text>
            <input className="rounded" type="text" name="phone"></input>
          </div>
          <div className="flex flex-col mb-3 mt-3">
            <text className="text-sm text-white">Messege</text>
            <input
              className="border-b rounded h-10"
              type="text"
              name="message"></input>
          </div>
        </div>
        <div className="lg:hidden">
          <div className="flex flex-col">
            <text className="text-sm text-white">First Name</text>
            <input
              className="rounded"
              type="text"
              name="first_name"></input>
          </div>
          <div className="flex flex-col mt-3">
            <text className="text-sm text-white">Last Name</text>
            <input className="rounded" type="text" name="last_name"></input>
          </div>
          <div className="flex flex-col mt-3">
            <text className="text-sm text-white">Email</text>
            <input className="rounded" type="email" name="email"></input>
          </div>
          <div className="flex flex-col mt-3">
            <text className="text-sm text-white">Phone Number</text>
            <input className="rounded" type="text" name="phone"></input>
          </div>
          <div className="flex flex-col mb-4 mt-3">
            <text className="text-sm text-white">Messege</text>
            <input
              className="border-b rounded h-20"
              type="text"
              name="message"></input>
          </div>
        </div>
        <input
          className="mt-14 lg:w-full w-4/5 py-2 bg-red-600 text-white rounded font-semibold hover:opacity-90 hover:cursor-pointer"
          type="submit"
          value="Send message"
        />
      </form>
    </div>
  );
}

export default ContactForm;
