import BGImageOne
    from "../assets/Frame 14.jpg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCalendarCheck,
    faDollarSign,
    faHandshake,
    faRepeat,
    faShield,
    faTruckMedical
} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import PrimaryButton from "./PrimaryButton";

const WhatWeOffer = () => {
    return (
        <div className="w-full flex lg:flex-row flex-col lg:justify-between lg:py-20 lg:px-24 ">
            <div className="flex flex-col w-full lg:w-6/12 my-20 px-6">
                <div className="text-5xl font-bold">
                    <text>Efficient & Trustable<br/>Transport Services</text>
                </div>
                <text className="mt-6 text-justify border-b-2 pb-5">
                    Sen Transport serves as the vital link seamlessly connecting pickup to delivery. Our platform harnesses advanced features, guaranteeing access to efficient & innovative delivery solutions.
                </text>
                <text className="text-lotus-red text-lg font-bold pt-5">
                    What We Offer
                </text>
                <text className="mt-4 text-justify">
                    At Sen Transport, Your satisfaction stands as our foremost commitment. Our streamlined operations ensure seamless accessibility, providing secure & efficient deliveries that alleviate any concerns or hassles on your part.
                </text>
                <div className="w-full mt-6 flex flex-col xl:flex-row xl:items-start items-center">
                    <div className="w-full xl:w-1/2 flex flex-col xl:items-start items-start">
                        <text className="font-bold"><FontAwesomeIcon icon={faRepeat} className="text-lotus-red mr-2"/>Real - Time Tracking</text>
                        <text className="font-bold"><FontAwesomeIcon icon={faDollarSign} className="text-lotus-red mr-2"/>Secure Payment Options</text>
                        <text className="font-bold"><FontAwesomeIcon icon={faShield} className="text-lotus-red mr-2"/>Transparent Pricing</text>
                        <text className="font-bold"><FontAwesomeIcon icon={faCalendarCheck} className="text-lotus-red mr-2"/>Pre - Planned Deliveries</text>
                    </div>
                    <div className="w-full xl:w-1/2 flex flex-col xl:items-start items-start">
                        <text className="font-bold"><FontAwesomeIcon icon={faHandshake} className="text-lotus-red mr-2"/>Zero hassle</text>
                        <text className="font-bold"><FontAwesomeIcon icon={faTruckMedical} className="text-lotus-red mr-2"/>Customer Support</text>
                        <text className="font-bold"><FontAwesomeIcon icon={faHandshake} className="text-lotus-red mr-2"/>Documentation Assistance</text>
                        <text className="font-bold"><FontAwesomeIcon icon={faHandshake} className="text-lotus-red mr-2"/>User-Friendly Interface</text>
                    </div>
                </div>
                <div className="my-12">
                    <PrimaryButton title={"More about us"} link={"/about"} />
                </div>
            </div>
            <div className="hidden lg:inline w-full lg:w-5/12 my-20">
                <img alt="SEN Transport" src={BGImageOne} width="600" height="100%" className="rounded-lg" />
            </div>
            <div className="lg:hidden w-full ">
                <img alt="SEN Transport" src={BGImageOne} width="600" height="100%" className="" />
            </div>
        </div>
    );
}

export default WhatWeOffer;