import React from "react";
import ServicesImg from "../assets/services-img.jpg";
import SenConnectLogo from "../assets/SEN Connect Dark Blue.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowUpRightFromSquare, faCircleCheck} from "@fortawesome/free-solid-svg-icons";
import ServiceCard from "./ServiceCard";
import localDistImg from "../assets/sen-local-dist.jpg";
import constHub from "../assets/const-hub.jpg";

const SenConnect = () => {
    const cardContent = [
        {
            image: localDistImg,
            title: "Sen Local Distribution",
            content: [
                "Bidding Options for Drivers",
                "Same-Day Delivery Services",
                "Time-Slot Delivery",
                "White-Glove Delivery",
                "Temperature-Controlled Delivery",
            ]
        },
        {
            image: constHub,
            title: "Sen Consolidated Hub",
            content: [
                "Temperature Controlled Delivery",
                "Cross Docking Services",
                "Hub & Spoke Distribution Model",
                "Last Mile Consolidation",
                "Vendor Managed Inventory (VMI)",
            ]
        },];

    return (
        <>
            <div className="w-full flex lg:flex-row items-center flex-col lg:justify-between lg:py-8 lg:px-24 ">
                <div className="flex flex-col w-full lg:w-6/12 my-20 px-6">
                    <div className="text-2xl text-lotus-red poppins-medium mb-1">
                        Just Relax, We'll Get It Delivered
                    </div>
                    <div className="w-full flex justify-start items-center">
                        <img alt="SEN Connect" src={SenConnectLogo} style={{maxWidth: '58%', width: 'auto'}}/>
                    </div>
                    <div className="text-lg text-black poppins-regular mb-4">
                        You're just a few steps away from enjoying
                    </div>
                    <div className="w-full flex flex-col xl:items-start items-start mb-4 ml-4">
                        <text className="font-bold"><FontAwesomeIcon icon={faCircleCheck}
                                                                     className="text-lotus-red mr-2"/>98 AUD offer
                        </text>
                        <text className="font-bold"><FontAwesomeIcon icon={faCircleCheck}
                                                                     className="text-lotus-red mr-2"/>24/7 Service
                        </text>
                        <text className="font-bold"><FontAwesomeIcon icon={faCircleCheck}
                                                                     className="text-lotus-red mr-2"/>Same Day Delivery
                        </text>
                        <text className="font-bold"><FontAwesomeIcon icon={faCircleCheck}
                                                                     className="text-lotus-red mr-2"/>Medical Deliveries
                        </text>
                        <text className="font-bold"><FontAwesomeIcon icon={faCircleCheck}
                                                                     className="text-lotus-red mr-2"/>Pre-Planned
                            Deliveries
                        </text>
                        <text className="font-bold"><FontAwesomeIcon icon={faCircleCheck}
                                                                     className="text-lotus-red mr-2"/>Palleted Product
                            Deliveries up to 3 tone
                        </text>
                    </div>
                    <div className="text-lg text-black poppins-regular mb-4">
                        Sen Connect is your all-in-one solution
                    </div>
                    <a href="https://senconnect.com.au/" target="_blank" rel="noreferrer">
                        <div className="flex justify-center items-center bg-lotus-red rounded py-4 "
                             style={{maxWidth: '58%', width: 'auto'}}>
                        <span className="flex items-center">
                        <text className="text-white poppins-medium mr-2">Sen Connect</text>
                        <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="text-white text-3xl"/>
                    </span>
                        </div>
                    </a>

                </div>
                <div className="hidden lg:inline w-full lg:w-5/12 my-20">
                    <img alt="SEN Transport" src={ServicesImg} width="600" height="100%" className="rounded-lg"/>
                </div>
                <div className="lg:hidden w-full ">
                    <img alt="SEN Transport" src={ServicesImg} width="600" height="100%" className=""/>
                </div>
            </div>
            <div className="w-full flex lg:flex-row items-stretch flex-col lg:justify-between lg:py-20 lg:px-24 ">
                <div className="flex flex-col lg:flex-row w-full my-2 px-6">
                    <div className="lg:mr-5 mb-4 w-full lg:w-1/2">
                        <ServiceCard image={cardContent[0].image} title={cardContent[0].title} content={cardContent[0].content}/>
                    </div>
                    <div className="lg:ml-5 w-full lg:w-1/2">
                        <ServiceCard image={cardContent[1].image} title={cardContent[1].title} content={cardContent[1].content}/>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SenConnect;