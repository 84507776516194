import careerContent from "../assets/career-content.jpg";
import React from "react";

const CareerOpps = () => {
    return (
        <>
            <div className="w-full flex lg:flex-row flex-col items-center lg:justify-between lg:py-10 lg:px-24 ">
                <div className="hidden lg:inline w-full lg:w-5/12 my-20">
                    <img alt="SEN Transport" src={careerContent} width="600" height="100%" className="rounded-lg" />
                </div>
                <div className="lg:hidden w-full ">
                    <img alt="SEN Transport" src={careerContent} width="600" height="100%" className="" />
                </div>
                <div className="flex flex-col w-full lg:w-6/12 my-20 px-6">
                    <text className="font-ExpletusSans-BoldItalic text-lg text-lotus-red">
                        Career Opportunities
                    </text>
                    <text className="text-5xl font-bold">Stay Connected for Future Opportunities</text>
                    <text className="mt-6 text-justify pb-5">
                        At the moment, there are no available job vacancies within our organization. However, we are committed to keeping you informed about any future career opportunities that may arise. Stay tuned for updates on this page, where we will regularly post information about new job openings. We appreciate your interest in joining our team, and we encourage you to check back regularly for the latest updates on exciting career prospects within our company.</text>
                </div>
            </div>
        </>
    );
}

export default CareerOpps;