import React, { useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import { Helmet } from "react-helmet-async";
import Hero from "../../components/Hero";
import careersImg from "../../assets/careers-img.jpg";
import CareerOpps from "../../components/CareerOpps";

function CareersPage() {

  return (
    <>
      <Helmet>
        <title>Careers - Sen Transport</title>
        <meta
          name="description"
          content="We're always seeking talent.
          Full Stack Developer
          Join us developing next genaration technologies on web
          Creative Designer
          Create creative UI/UX, Logo designs and Graphic designs
          "
        />
        <link rel="canonical" href="/careers" />
      </Helmet>
      <div className="w-full h-screen">
        <Hero type={"Careers"} imgSrc={careersImg}/>
      </div>
      <CareerOpps/>
      <Footer/>
    </>
  );
}

export default CareersPage;
