import React, {useRef, useEffect} from "react";
import Footer from "../components/Footer/Footer";
import GoogleMapContactUs from "../components/GoogleMapContactUs";
import {Helmet} from "react-helmet-async";
import Hero from "../components/Hero";
import contactUsImg from "../assets/contact-us-bg.jpg";
import ContactForm from "../components/ContactForm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebook, faLinkedin, faSquareInstagram} from "@fortawesome/free-brands-svg-icons";

function ContactUs() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const ref = useRef(null);

    const handleClick = () => {
        ref.current?.scrollIntoView({behavior: "smooth"});
    };
    return (
        <>
            <Helmet>
                <title>Contact US - Sen Transport</title>
                <meta
                    name="description"
                    content="Get In Touch, We are ready to assist you anytime, every time"
                />
                <link rel="canonical" href="/contact"/>
            </Helmet>
            <div className="w-full h-screen">
                <Hero type={"ContactUs"} imgSrc={contactUsImg}/>
            </div>
            <ContactForm/>

            <div className="w-full flex lg:flex-row flex-col lg:justify-between py-10 lg:px-24 px-10">
                <div className="flex flex-col w-full">
                    <text className="font-ExpletusSans-BoldItalic text-lg text-lotus-red">
                        Social Media
                    </text>
                    <text className="text-5xl font-bold">Find us on</text>
                    <div className="flex flex-row mt-10 border-2 rounded-xl p-10 border-collapse w-full border-black">
                        <div className="flex w-1/3 flex-col justify-center items-center border-r-2 border-black">
                            <a href="https://www.facebook.com/profile.php?id=100092169171736&mibextid=9R9pXO"
                               target="_blank" rel="noreferrer">
                                <div className="flex flex-col justify-center items-center">
                                    <FontAwesomeIcon icon={faFacebook} size="3x" color="black"/>
                                    <text className="text-black font-bold mt-2">Facebook</text>
                                </div>
                            </a>
                        </div>
                        <div className="flex w-1/3 flex-col justify-center items-center border-r-2 border-black">
                            <a href="https://instagram.com/sen.transport?igshid=NjIwNzIyMDk2Mg=="
                               target="_blank" rel="noreferrer">
                                <div className="flex flex-col justify-center items-center">
                                    <FontAwesomeIcon
                                        icon={faSquareInstagram}
                                        size="3x"
                                        color="black"
                                    />
                                    <text className="text-black font-bold mt-2">Instagram</text>
                                </div>
                            </a>
                        </div>
                        <div className="flex w-1/3 flex-col justify-center items-center">
                            <a href="https://www.linkedin.com/company/sentransport/"
                               target="_blank" rel="noreferrer">
                                <div className="flex flex-col justify-center items-center">
                                    <FontAwesomeIcon
                                        icon={faLinkedin}
                                        size="3x"
                                        color="black"
                                    />
                                    <text className="text-black font-bold mt-2">LinkedIn</text>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <GoogleMapContactUs/>
            <Footer/>
        </>
    );
}

export default ContactUs;
