import React from "react";

function AchivementAboutUs() {
  return (
    <div className="lg:my-36 lg:px-24 lg:py-5 my-24 px-10">
      <div className="flex justify-center flex-col items-center lg:my-6">
        <text className="font-ExpletusSans-BoldItalic text-xl text-black">
          Achievements
        </text>
        <text className="poppins-medium lg:text-5xl text-3xl text-center text-lotus-red">
          High Work Achievements
        </text>
      </div>

      <div className="flex lg:flex-row flex-col justify-center lg:my-8 my-8 lg:ml-0 ml-8 border-2 border-black rounded-xl p-10">
        <div className="mt-8 lg:mt-0 lg:border-r-2 lg:border-black w-1/3">
          <div className="flex flex-col text-center">
            <text className="text-6xl text-lotus-red font-bold">50</text>
            <text className="text-2xl font-bold">Cities</text>
          </div>
        </div>
        <div className="mt-8 lg:mt-0 lg:border-r-2 lg:border-black w-1/3">
          <div className="flex flex-col text-center">
            <text className="text-6xl text-lotus-red font-bold">02 Million</text>
            <text className="text-2xl font-bold">Kilometers Travelled</text>
          </div>
        </div>
        <div className="mt-8 lg:mt-0 w-1/3">
          <div className="flex flex-col text-center">
            <text className="text-6xl text-lotus-red font-bold">350</text>
            <text className="text-2xl font-bold">Clients</text>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AchivementAboutUs;
