import React from "react";

const TimeLineCard = (props) => {
    return(
        <div className="p-6 w-full flex flex-col service-card mt-4 rounded-lg text-center">
            <text className="text-3xl font-bold text-lotus-red">{props.year}</text>
            <text className="text-sm font-bold">{props.title}</text>
            <text className="text-xs mt-4">
                {props.desc}
            </text>
        </div>
    );
}

export default TimeLineCard;